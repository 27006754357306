import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, useLocation } from "react-router-dom";
import AOS from "aos";

import { useAosApply } from "@common/hooks";
import { AppProvider } from "@common/app-provider";

// Note: don't change the order
import "@common/theme/root.css";
import "@common/theme/global.css";
import "@common/theme/text.theme.css";
import "@common/theme/title.theme.css";
import "@common/theme/global.override.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
const builderType = "shop";

let RenderApp = () => {
  const [Component, setComponent] = useState(null);
  const location = useLocation();

  useAosApply(location.pathname);

  useEffect(() => {
    AOS.init({
      offset: 100,
    });

    const loadComponent = async () => {
      if (builderType === "shop") {
        const { default: ShopBuilder } = await import("./builder/shop/app.jsx");
        setComponent(() => ShopBuilder);
      }
    };

    loadComponent();
  }, []);

  if (!Component) {
    return;
  }

  return <Component />;
};

root.render(
  <BrowserRouter>
    <AppProvider>
      <RenderApp />
    </AppProvider>
  </BrowserRouter>
);
